import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { reviews } from './reviewsData';
import './Testimonials.css';
import trustpilot from "../img/trustpilot_icon.png";
import React from "react";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
    const { t } = useTranslation();

    // Определяем, является ли устройство мобильным
    const isMobile = window.innerWidth <= 576;

    return (
        <section className="testimonial-container">
            <div className="title">
                <h1>{t('testimonials')}</h1>
            </div>

            <div className="slider-container">
                <Splide
                    options={{
                        perPage: 1,
                        gap: '1rem',
                        autoplay: true,
                        speed: 1000,
                        rewind: true,
                        rewindByDrag: true,
                        arrows: !isMobile, // Отключаем стрелки на мобильных
                    }}
                >
                    {reviews.map((review) => (
                        <SplideSlide key={review.id}>
                            <img className="review-img" src={review.image} alt=""/>
                            <div className="content">
                                <p className="text">{review.text}</p>
                                <div className="info">
                                    <div className="rating">
                                        {[...Array(6)].map((_, i) => (
                                            <span key={i} className="star filled">&#9733;</span>
                                        ))}
                                    </div>
                                    <p className="user">{review.name}</p>
                                    <p className="position">{review.position}</p>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>

            <a
                href="https://www.trustpilot.com/review/agilets.de"
                target="_blank"
                rel="noopener noreferrer"
                className="btn mt-3 mb-3 border-0 py-2 px-3"
            >
                <div className="trustpilot">
                    <div className="trustpilot_logo">
                        <img src={trustpilot} alt="trustpilot"/>
                    </div>
                </div>
            </a>
        </section>
    );
};

export default Testimonials;
